<template>
    <vx-card>
        <div class="container">
            <h1> Ventes </h1>
            <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
        </div>
    </vx-card>
</template>

<script>
import apexchart from 'vue-apexcharts'
import Utils from '@/assets/utils/utils'
import Fdate from '@/assets/utils/fdate'
import cDate from '@/components/app/date'

import Terminal from '@/database/models/terminal'
import Invoice from '@/database/models/invoice'
import Item from '@/database/models/item'
import Member from '@/database/models/member'
import seanceGraphe from '@/components/stats/components/graphFactComp/seanceG.vue'
import accessoireG from '@/components/stats/components/graphFactComp/accessoireG.vue'
import VxCard from '@/components/vx-card/VxCard.vue'


export default {
    
    name:'facturationGraph',
    components:{
        apexchart,
        Item,
        Invoice,
        cDate,
        Member,
        Terminal,
        seanceGraphe,
        accessoireG,
        VxCard
        
       
    
    },
    props:[
        'listfact',
        
    ],
    data(){
        return {
            series: [{
                name:"Quantité ",
                data: []
            }],
            chartOptions: {
                chart: {
                type: 'bar',
                height: 350
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [],
                }
            },
            list_facture:[],
            vente_seance:[],
            vente_accesoire:[],
            total:0,
            dmin:1,
            dmax:(new Date()).getMonth()+1,
            listMoisItem:{},
            
           devise: {}
        }
    },
    
    mounted:function(){
        this.$srvApi.Req('get', 'public/currency')
        .then(( rep )=>{
            this.devise = rep.data;
            this.frefresh()
        })
    },
    methods:{
        getData(list){

            // on revoit la liste des factures 
            let listName=[];
            let listQte=[];
            let listTotal=[];

            for (let i =0; i<list.length; i++){
                

                // on verifie qu'il ne s'agit pas d'une facture, car sinon item = null 
                if (list[i].type !="Reçu"){
                    
                    // on recupere les données de l'item grâce à l'id de la facture 
                    Item.getTabAllMemoryKey( list[i].uid, function(items)
                    {
                        // pour l'instant on distingue pas accesoire et seance 

                        
                            for (let j=0; j<items.length; j++){
                                if (listName.includes(items[j].name)){

                                    
                                    let x = listName.indexOf(items[j].name)
                                    // let x = listName
                                    // let x = -1;
                                    // for (let j=0; j < listName.length; j++){
                                    //     if (listName[j]==items[j].name){
                                    //         x=j;
                                    //     }
                                    // }
                                    if(items[j].type!="av"){
                                        listQte[x]=listQte[x]+1;
                                    }
                                    else{
                                        listQte[x]=listQte[x]-1;
                                    }
                                    listTotal[x]=listTotal[x]+parseFloat(items[j].prix);

                                }
                                else{
                                    if(items[j].type!="av"){
                                        listQte.push(1);
                                    }
                                    else{
                                        listQte.push(-1);
                                    }
                                    listName.push(items[j].name);
                                    
                                    listTotal.push(parseFloat(items[j].prix.toFixed(2)))
                                    // todo : decimal
                                }
                            }
                        //}
                        
                    })
                    
                }
            }
            
            
            let grandeList=[];
            // on met tout dan le data qu'utilisera apex 
            for (let i=0; i<listName.length; i++){
                let petiteList ={
                    x : listName[i],
                    y : listQte[i],
                }
                grandeList.push(petiteList);
            }
            
            
            this.updateChart(listQte, listTotal);
            
            this.updateOption(listName)
            
            
        },
        updateChart(list, list2 ) {
    
            this.series = [{
                name:'Quantité ',
                data: list
            },
            {
                name:" CA",
                data:list2
            }]
      },
      updateOption(list){
          
          
          this.chartOptions={
              xaxis:{
                  categories:list
              }
          }
      },
      

    frefresh()
    {
        
        const li=[];
        const seance=[];
        const acc=[];
        
        
        Invoice.getAllMemory(function(invoice){
            
            let list = []
            // o,n recupere les id 
            for (let inv in invoice){
                list.push(inv);
            }
           
            let today = new Date();
            let y = today.getFullYear();
            //let mois = today.getMonth()+1;
            
            let factY =[];
            // on recupere les invoices de cette année 
            for(let i=0; i<list.length; i++){
                if (invoice[list[i]].createAt.getFullYear()==y){
                    factY.push(invoice[list[i]]);
                }
            }
           
            
            // mtn il faut recuperer les factures de chaques mois 

            let monthFact = [];
            for (let i=0; i<12; i++){
                monthFact.push([]);
            }
            
            // je recuper les factures en fonction des mois -- ok 
            for (let m =1; m<=today.getMonth()+1; m++){
                let mList=[];
                
                for (let i =0; i<factY.length; i++){
                   
                   if ((factY[i].createAt.getMonth()+1)===m){
                        
                        mList.push(factY[i]);
                    }
                }
                
                monthFact[m-1]=mList;
            }
           
        
           
        // ----   -------------
        
     

        function completeDate(buffer, listF){   
            
            
            
            Member.getMemory( buffer.member, function(mbr)
            {
                    //Si pas de membre ?
                    let p_member = { first_name:'Aucun', last_name:'' };
                    if(mbr)
                        p_member = mbr;
                    
                    //get vendor
                    Member.getMemory(  buffer.vendor, function(ved)
                    {
                        let p_vendor = { first_name:'Aucun', last_name:'' };
                        if(ved)
                            p_vendor = ved;

                        //payement
                        let paye = []
                        if(buffer.pay_money!=0)
                            paye.push({name:'ESP', value:buffer.pay_money})

                        if(buffer.pay_cb!=0)
                            paye.push({name:'CB',value:buffer.pay_cb})

                        if(buffer.pay_check!=0)
                            paye.push({name:'CHEQ',value:buffer.pay_check})

                        if(buffer.pay_dif!=0)
                            paye.push({name:'DIFF',value:buffer.pay_dif})

                        if(buffer.pay_prele!=0)
                            paye.push({name:'PREL',value:buffer.pay_prele})

                        //Item
                       
                      
                        Item.getTabAllMemoryKey( buffer.uid, function(items)
                        {
                           
                            //add

                           

                            listF.push({
                                uid      : buffer.uid,
                                type     : Invoice.getTypeName(buffer),
                                date     : Fdate.getFullDateMin(buffer.createAt,true)+" "+Fdate.getHeure(buffer.createAt),
                                vdate    : Fdate.getFullDateMin(buffer.createAt),
                                number   : buffer.number,
                                vendor   : {'first_name':Utils.StrCapital(p_vendor.first_name), 'last_name':Utils.StrCapital(p_vendor.last_name)},
                                member   : {'first_name':Utils.StrCapital(p_member.first_name), 'last_name':Utils.StrCapital(p_member.last_name)},
                                item     : items,
                                payement : paye,
                                total    : (buffer.pay_cb + buffer.pay_money + buffer.pay_check + buffer.pay_dif + buffer.pay_prele).toFixed(2),
                                // todo : decimal
                            });
                           
                           
                        });
                       
                    });
                    
            });
            
            
        }
           
            function calcul(list, s,a ){
               
                for (let it=0; it<list.length; it++){
                    
                    // if(ite.prix!=0)
                    // if(ite.tva!=0)
                    if(list[it].type!='Reçu')
                    {
                         
                        for (let j=0; j<list[it].item.length; j++){
                            let ite = list[it].item[j];
                            
                            let total = 0
                            //Si Facture
                            if( ite.type =='fa' || ite.type =='re' )
                                total = parseFloat((ite.prix * ((ite.tva/100)+1)).toFixed(2))
                                // todo : decimal
                                
                            //Sinon avoir
                            if( ite.type =='av' )
                                total = parseFloat((ite.prix * (( (ite.tva*-1)/100)+1)).toFixed(2))
                                // todo : decimal
                            if( ite.credit!=0){
                                
                                s += total
                            }
                            else
                                a += total
                            
                           
                            
                        }
                        
                    }
                    if( it==list.length-1){
                        seance.push(s);
                        acc.push(a);
                    }
                  
                }
                


            }
           
           for (let v =0; v<monthFact.length; v++){
               let listF=[];
               let se =0;
               let ac=0;
               for(let j=0; j<monthFact[v].length; j++){
                   completeDate(monthFact[v][j],listF)
               }
               li.push(listF)
               calcul(li[v], se, ac)
               
               
                
           }


           
        });

        for (let g=seance.length; g<12; g++){
            seance.push(0)
        }
        for (let g=acc.length; g<12; g++){
            acc.push(0)
        }

       // on recupere les infos pour chaque mois 
        this.listMoisItem=li;
        this.vente_seance=seance;
        this.vente_accesoire=acc;
    },
    // manque à calculer ventes séance, et ventes accessoire
    
}
    
}
</script>


<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
.espace{
    padding-left: 5px;

}
</style>